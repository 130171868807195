// Event Management
$(document).ready(function () {
	function ev (c, a, l, v) {
		l = l || '';
		v = v || 1;
		if (ga) ga('send', 'event', c, a, l, v);
	}

	$('a[href*="mailto:"]').click(function () {
		ev('Contact', 'click', 'E-mail', 1);
	});
	$('a[href*="tel:"]').click(function () {
		ev('Contact', 'click', 'E-mail', 1);
	});
});


// Scroll effect / { reset: true }

window.sr = ScrollReveal();
sr.reveal('div:not(.immediate)');

// Scroll to ID / #removal

$(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1500);
        return false;
      }
    }
  });
});


//Open Burger menu

$('.burger-menu, .menu .close, .menu-sub-layer').click(function(){
    $('.menu').toggleClass("open");
    $('body').toggleClass("translate"); 
});

//Inner menu trigger

$('.inner-menu-trigger').click(function(){
    $('.inner-menu').toggleClass("open-inner");
});

// MAP

var targeted = '48.405743, 2.686344';
var waypoints = [[48.403670, 2.692141]];

function initMap() {
    var arbresec = {lat: 48.399858, lng: 2.691468};
    var directionsService = new google.maps.DirectionsService();
    var directionsDisplay = new google.maps.DirectionsRenderer();
    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 16,
        center: arbresec
    });
    directionsDisplay.setMap(map);

    calculateAndDisplayRoute(directionsService, directionsDisplay);

    var onChangeHandler = function() {
        calculateAndDisplayRoute(directionsService, directionsDisplay);
    };
        
    $('.map-menu ul li').click(function(){
        
        targeted = $(this).attr('value');
        waypoints = $(this).data('waypoints') || [];
        
        $('.map-menu ul li').removeClass('active');
        $(this).addClass('active');
        calculateAndDisplayRoute(directionsService, directionsDisplay);
    });


    var marker = new google.maps.Marker({
        position: arbresec,
        map: map
    });
}




// Draw MAP Direction

function calculateAndDisplayRoute(directionsService, directionsDisplay) {

	console.log(waypoints);
	
	var config = {
        origin: '48.399858, 2.691468',
        destination: targeted,
        travelMode: 'WALKING'
    };
    
    if(waypoints.length > 0){
	    var steps = [];
	    for ( var i in waypoints){
		    var waypoint = waypoints[i];
		    steps.push({
			    location: { lat: waypoint[0], lng: waypoint[1] },
			    stopover: false
		    });
	    }
	    console.log(steps);
	    config.waypoints = steps;
    }

    directionsService.route(config, function(response, status) {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
          } else {
            window.alert('Directions request failed due to ' + status);
          }
        });
}

//


$(document).ready( function(){
	$('#contact_form input, #contact_form textarea').focus(function(){
		$(this).removeClass('error_warning');
	});
	
	$('#contact_form').submit(function(evt){
		
		var error = false;
		
		$(this).find('input').each(function(){
			if($(this).val().length <= 0 ){
				$(this).addClass('error_warning');
				error = true;
			}
		});
		
		if(error){
			evt.preventDefault();
			return false;
		}
		
		return true;
	});
	
	var thanksmodal = $('.message_thanks');
	if( thanksmodal.length > 0 ) {
		thanksmodal.fadeIn();
	}
	
	console.log( thanksmodal.length );
	$('.message_thanks .close').click(function(){
		$('.message_thanks').fadeOut();
	});
	
});



